import { z } from 'zod';
import { Company, CompanyApiSchema } from './company';
import { Address, AddressApiSchema } from './address';
import { Contact, UserApiSchema } from './user';
import { Variant, VariantApiSchema } from './product';
import { RequestParams } from './common-types';

export const LineItemApiSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  rank: z.number(),
  allow_discount: z.nullable(z.boolean()).optional(),
  cart_id: z.nullable(z.string()).optional(),
  description: z.nullable(z.string()).optional(),
  discount_total: z.nullable(z.number()).optional(),
  fulfilled_quantity: z.nullable(z.number()).optional(),
  has_shipping: z.nullable(z.boolean()).optional(),
  is_giftcard: z.nullable(z.boolean()).optional(),
  is_return: z.nullable(z.boolean()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  order_edit_id: z.nullable(z.string()).optional(),
  order_id: z.nullable(z.string()).optional(),
  original_item_id: z.nullable(z.string()).optional(),
  original_tax_total: z.nullable(z.number()).optional(),
  original_total: z.nullable(z.number()).optional(),
  quantity: z.nullable(z.number()).optional(),
  raw_discount_total: z.nullable(z.number()).optional(),
  returned_quantity: z.nullable(z.number()).optional(),
  shipped_quantity: z.nullable(z.number()).optional(),
  should_merge: z.nullable(z.boolean()).optional(),
  subtotal: z.nullable(z.number()).optional(),
  tax_total: z.nullable(z.number()).optional(),
  swap_id: z.nullable(z.string()).optional(),
  total: z.nullable(z.number()).optional(),
  unit_price: z.nullable(z.number()).optional(),
  thumbnail: z.nullable(z.string().url()).optional(),
  title: z.nullable(z.string()).optional(),
  variant_id: z.nullable(z.string()).optional(),
  order: z
    .nullable(
      z.object({
        seller_order_id: z.nullable(z.string()).optional(),
        external_id: z.nullable(z.string()).optional(),
        display_id: z.nullable(z.number()).optional(),
      }),
    )
    .optional(),
  variant: z.nullable(VariantApiSchema).optional(),
  tax_lines: z
    .nullable(
      z.array(
        z.object({
          rate: z.nullable(z.number()).optional(),
          code: z.nullable(z.string()).optional(),
          item_id: z.nullable(z.string()).optional(),
          name: z.nullable(z.string()).optional(),
        }),
      ),
    )
    .optional(),
});

export const OrderEditChangeApiSchema = z.object({
  id: z.string(),
  type: z.union([
    z.literal('item_add'),
    z.literal('item_remove'),
    z.literal('item_update'),
  ]),
  order_edit_id: z.string(),
  original_line_item_id: z.nullable(z.string()).optional(),
  line_item_id: z.nullable(z.string()),
  created_at: z.string(),
  updated_at: z.string(),
  deleted_at: z.nullable(z.string()),
  original_line_item: z.nullable(LineItemApiSchema).optional(),
  line_item: z.nullable(LineItemApiSchema).optional(),
});

export const OrderEditApiSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  order_id: z.string(),
  internal_note: z.nullable(z.string()),
  created_by: z.string(),
  status: z.union([
    z.literal('confirmed'),
    z.literal('declined'),
    z.literal('requested'),
    z.literal('created'),
    z.literal('canceled'),
  ]),
  changes: z.array(OrderEditChangeApiSchema).optional(),
  items: z.array(LineItemApiSchema).optional(),
  total: z.number().optional(),
  subtotal: z.number().optional(),
  tax_total: z.number().optional(),
});

export const PaymentApiSchema = z.object({
  id: z.string(),
  provider_id: z.string(),
  amount: z.nullable(z.number()).optional(),
  amount_refunded: z.nullable(z.number()).optional(),
  captured_at: z.nullable(z.string()).optional(),
  created_at: z.string().optional(),
  data: z
    .object({
      payment_method: z.nullable(z.string().optional()),
      payment_method_data: z
        .nullable(
          z.object({
            delay: z.number().or(z.string()).optional(),
          }),
        )
        .optional(),
    })
    .optional(),
});

export const FulfillmentApiSchema = z.object({
  id: z.string(),
  created_at: z.string(),
  updated_at: z.nullable(z.string()).optional(),
  canceled_at: z.nullable(z.string()).optional(),
  idempotency_key: z.nullable(z.string()).optional(),
  location_id: z.nullable(z.string()).optional(),
  metadata: z.nullable(z.record(z.unknown())).optional(),
  order_id: z.string(),
  tracking_numbers: z.array(z.string()).optional(),
  shipped_at: z.nullable(z.string()).optional(),
  items: z.array(
    z.object({
      fulfillment_id: z.string(),
      item_id: z.string(),
      quantity: z.number(),
    }),
  ),
  tracking_links: z
    .array(
      z.object({
        tracking_number: z.string(),
        url: z.nullable(z.string()).optional(),
        quantity: z.number(),
        metadata: z.nullable(z.record(z.unknown())).optional(),
      }),
    )
    .optional(),
});

export const OrderApiSchema = z.object({
  id: z.string(),
  status: z.union([
    z.literal('reserved'),
    z.literal('pending'),
    z.literal('completed'),
    z.literal('archived'),
    z.literal('canceled'),
    z.literal('rejected'),
    z.literal('requires_action'),
  ]),
  fulfillment_status: z.union([
    z.literal('not_fulfilled'),
    z.literal('partially_fulfilled'),
    z.literal('fulfilled'),
    z.literal('partially_shipped'),
    z.literal('shipped'),
    z.literal('partially_returned'),
    z.literal('returned'),
    z.literal('canceled'),
    z.literal('requires_action'),
  ]),
  payment_status: z.union([
    z.literal('awaiting'),
    z.literal('not_paid'),
    z.literal('captured'),
    z.literal('partially_refunded'),
    z.literal('refunded'),
    z.literal('canceled'),
    z.literal('requires_action'),
  ]),
  origin: z
    .nullable(
      z.union([
        z.literal('cart'),
        z.literal('import'),
        z.literal('document'),
        z.literal('quotation'),
      ]),
    )
    .optional(),
  display_id: z.string().or(z.number()),
  seller_order_id: z.nullable(z.string()).optional(),
  external_id: z.nullable(z.string()).optional(),
  cart_id: z.nullable(z.string()),
  total: z.nullable(z.number()).optional(),
  subtotal: z.nullable(z.number()).optional(),
  tax_total: z.nullable(z.number()).optional(),
  discount_total: z.nullable(z.number()).optional(),
  created_at: z.string(),
  updated_at: z.string(),
  rejected_at: z.nullable(z.string().or(z.date())).optional(),
  delivery_date: z.nullable(z.string().or(z.date())).optional(),
  rejection_reason: z.nullable(z.string()).optional(),
  shipping_total: z.nullable(z.number()).optional(),
  fulfillments: z.nullable(z.array(FulfillmentApiSchema)).optional(),
  company: z.nullable(CompanyApiSchema).optional(),
  billing_address: z.nullable(AddressApiSchema).optional(),
  shipping_address: z.nullable(AddressApiSchema).optional(),
  customer: z.nullable(UserApiSchema).optional(),
  items: z.nullable(z.array(LineItemApiSchema)).optional(),
  payments: z.nullable(z.array(PaymentApiSchema).optional()).optional(),
  receipt_url: z.nullable(z.string()).optional(),
  metadata: z
    .nullable(
      z.object({
        comment: z.string().optional(),
        invoice_url: z.string().optional(),
        delivery_note_url: z.string().optional(),
        erp_tracking_name: z.string().optional(),
        erp_tracking_url: z.string().optional(),
        smart_order_purchase_order_url: z.nullable(z.string()).optional(),
        smart_order_purchase_order_converted: z.string().optional(),
        smart_order_purchase_order_email_html: z
          .nullable(z.string())
          .optional(),
        smart_order_purchase_order_email_txt: z.nullable(z.string()).optional(),
        smart_order_purchase_order_sender_email: z
          .nullable(z.string())
          .optional(),
        smart_order_errors: z.nullable(z.array(z.any())).optional(),
        by_seller: z.nullable(z.string()).optional(),
      }),
    )
    .optional(),
  draft_order: z
    .nullable(
      z.object({
        id: z.string(),
        display_id: z.nullable(z.string()).optional(),
        metadata: z.nullable(z.record(z.unknown())).optional(),
      }),
    )
    .optional(),
});

export type PaymentFromApi = z.infer<typeof PaymentApiSchema>;
export type LineItemFromApi = z.infer<typeof LineItemApiSchema>;
export type FulfillmentFromApi = z.infer<typeof FulfillmentApiSchema>;
export type OrderFromApi = z.infer<typeof OrderApiSchema>;
export type OrderEditFromApi = z.infer<typeof OrderEditApiSchema>;

export enum OrderComputedStatus {
  RESERVED,
  CANCELED,
  REJECTED,
  PENDING,
  VALIDATED,
  PARTIALLY_FULFILLED,
  READY_TO_SHIP,
  PARTIALLY_SHIPPED,
  SHIPPED,
}

export enum OrderPaymentStatus {
  NOT_PAID = 'not_paid',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially_paid',
}

export type OrderItem = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  rank: number;
  reference: string;
  cartId?: string | null;
  title?: string | null;
  description?: string | null;
  thumbnail?: string | null;
  quantity: number;
  unitPrice: number;
  total: number | null;
  subtotal?: number | null;
  taxTotal?: number | null;
  variantId?: string | null;
  inventory?: number;
  pcb?: number;
  moq?: number;
  hasShipping: boolean | null;
  fulfilledQuantity: number | null;
  shippedQuantity: number | null;
  variant?: Variant;
  displayUnit: string | null;
  storageUnit: string | null;
  decimal: boolean;
  metadata?: Record<string, unknown> | null;
};

export type OrderEditItem = OrderItem & {
  orderEditId: string;
  originalItemId: string;
  orderId: string;
  originalTaxTotal: number;
};

export type Fulfillment = {
  id: string;
  createdAt: Date;
  updatedAt?: Date;
  canceledAt?: Date | null;
  idempotencyKey?: string | null;
  locationId?: string | null;
  metadata?: Record<string, unknown> | null;
  orderId: string;
  trackingNumbers?: string[];
  shippedAt?: Date | null;
  items?: {
    fulfillmentId: string;
    itemId: string;
    quantity: number;
  }[];
  invoiceUrl?: string;
};

export type OrderOrigin = 'cart' | 'import' | 'document' | 'quotation';

export type Order = {
  id: string;
  displayId: string;
  externalId?: string | null;
  sellerOrderId?: string | null;
  cartId: string | null;
  company?: Company | null;
  companyName: string | null;
  companyId: string | null;
  createdAt: Date;
  updatedAt: Date;
  rejectedAt?: Date;
  rejectionReason?: string;
  deliveryDate?: Date | null;
  status: OrderFromApi['status'];
  fulfillmentStatus: OrderFromApi['fulfillment_status'];
  paymentStatus: OrderFromApi['payment_status'];
  total?: number | null;
  subtotal?: number | null;
  shippingTotal?: number | null;
  taxTotal?: number | null;
  discountTotal?: number | null;
  items?: OrderItem[];
  fulfillments?: Fulfillment[];
  shippingAddress?: Address | null;
  billingAddress?: Address | null;
  contact: Contact | null;
  paymentMethod?: string;
  paymentDelay?: number | null;
  comment?: string;
  articlesCount: number; // total quantity of articles
  refsCount?: number; // total of different articles
  computedStatus: OrderComputedStatus;
  computedPaymentStatus: OrderPaymentStatus;
  isPreorder: boolean;
  receiptUrl?: string | null;
  invoiceUrl?: string | null;
  deliveryNoteUrl?: string | null;
  erpTrackingName?: string | null;
  erpTrackingUrl?: string | null;
  purchaseOrderUrl?: string | null;
  isFromNewCompany: boolean;
  origin?: OrderOrigin | null;
  smartOrderSource?: 'email' | 'upload' | null;
  bySeller?: boolean;
  customerId?: string | null;
  metadata?: Record<string, unknown> | null;
  smartOrder?: {
    id?: string | null;
    displayId?: string | null;
    isTest?: boolean;
  };
};

export type OrderEditChange = {
  id: string;
  lineItemId: string;
  orderEditId: string;
  originalLineItemId: string;
  type: 'item_add' | 'item_remove' | 'item_update';
  lineItem: OrderItem;
  originalLineItem: OrderItem;
};

export type OrderEdit = {
  id: string;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  orderId: string;
  internalNote?: string;
  status?: 'confirmed' | 'declined' | 'canceled' | 'requested' | 'created';
  changes: OrderEditChange[];
  items: OrderEditItem[];
  total: number;
  subtotal: number;
  taxTotal: number;
};

export type OrderQueryParameters = RequestParams & {
  id?: string;
  company_id?: string;
  agent_id?: string;
  status?: string[];
  fulfillment_status?: string[];
  date_from?: string;
  date_to?: string;
  created_at_from?: string;
  created_at_to?: string;
  orderBy?: 'created_at' | 'updated_at';
  page?: string;
};

export type CreateFulfillmentPayload = {
  items: {
    item_id: string;
    quantity: number;
  }[];
};

export type FulfillmentView = {
  id: string;
  createdAt: Date;
  canceled: boolean;
  refs: number;
  articles: number;
  shipped: boolean;
  fulfilledItems: OrderItem[];
};

export type UpdateOrderType = {
  delivery_date?: string | Date;
};

export type ActiveFilter = {
  type: 'agent' | 'status' | 'date';
  id: string;
  value: string;
};

export type EditLineItemRow = {
  id: string;
  reference: string;
  title: string;
  quantity: number;
  unitPrice: number;
  total: number;
  remainingQuantity: number;
  checked: boolean;
  displayUnit: string | null;
  storageUnit: string | null;
  decimal: boolean;
};

export type EditOrderItemRow = {
  id?: string;
  rank: number;
  variantId: string;
  reference: string;
  title: string;
  quantity: number;
  inventoryQuantity: number;
  unitPrice: number;
  isAdded: boolean;
  displayUnit: string | null;
  storageUnit: string | null;
  decimal: boolean;
};

export enum SmartOrderItemErrorsEnum {
  PRODUCT_NOT_FOUND_IN_OPENAI = 'productNotFoundInOpenAI',
  PRODUCT_NOT_FOUND_IN_DOCUMENTAI = 'productNotFoundInDocumentAI',
  QUANTITY_NOT_MATCHING_BETWEEN_DOCUMENTAI_AND_OPENAI = 'quantityNotMatchingBetweenDocumentAIAndOpenAI',
  UNIT_PRICE_NOT_MATCHING_BETWEEN_DOCUMENTAI_AND_OPENAI = 'unitPriceNotMatchingBetweenDocumentAIAndOpenAI',
  UNIT_PRICE_NOT_MATCHING_BETWEEN_CATALOG_AND_PURCHASEORDER = 'unitPriceNotMatchingBetweenCatalogAndPurchaseOrder',
  PURCHASE_ORDER_ID_NOT_MATCHING_BETWEEN_DOCUMENTAI_AND_OPENAI = 'orderIdNotMatchingBetweenDocumentAIAndOpenAI',
  PURCHASE_ORDER_ID_NOT_FOUND = 'orderIdNotFound',
}
